import React from 'react'

import { Head } from '../../components/Head'
import { MainPageExample } from '../../examples/html/pages/MainPageExample'

const Page = () => (
  <>
    <Head title="Ammattitieto" />
    <MainPageExample />
  </>
)

export default Page
